<template>
  <div class="sidebar">
    <div class="sidebar-logo">
      <ui-router-link :to="{ name: 'Orders' }" direction="root">
        <logo-commutuus />
      </ui-router-link>
    </div>
    <div class="sidebar-content">
      <OrganizationSwitch class="organization-switch" small />
      <app-sidebar-item :to="{ name: 'Orders' }" :label="$t('nav.sidebar.ordersTab')" :active="currentRoute === 'Orders'" data-testid="orders-tab" class="tab" v-if="shouldShowTab('OrdersView')" />
      <app-sidebar-item :to="{ name: 'Returns' }" :label="$t('nav.sidebar.returnsTab')" :active="currentRoute === 'Returns'" data-testid="returns-tab" class="tab" v-if="shouldShowTab('ReturnsView')" />
      <app-sidebar-item :to="{ name: 'Items' }" :label="$t('nav.sidebar.itemsTab')" :active="currentRoute === 'Items'" data-testid="items-tab" class="tab" v-if="shouldShowTab('ItemsView')" />
      <app-sidebar-item :to="{ name: 'More' }" :label="$t('nav.sidebar.moreTab')" :active="currentRoute === 'More'" data-testid="more-tab" class="tab" v-if="shouldShowTab('MoreView')" />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import useUserStore from '@/stores/UserStore';
// eslint-disable-next-line import/extensions
import useOrganizationsStore from '@/stores/OrganizationsStore';

import LogoCommutuus from '@/components/logos/LogoCommutuus.vue';
import UiRouterLink from '@/components/ui/UIRouterLink.vue';
import AppSidebarItem from '@/components/layout/AppSidebarItem.vue';
import OrganizationSwitch from '@/components/organization-switch/OrganizationSwitch.vue';

export default {
  name: 'AppSidebar',
  components: {
    LogoCommutuus,
    UiRouterLink,
    AppSidebarItem,
    OrganizationSwitch,
  },
  props: {
  },
  setup() {
    const userStore = useUserStore();
    const organizationsStore = useOrganizationsStore();

    return {
      userStore,
      organizationsStore,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    user() {
      return this.userStore.user;
    },
    selectedOrganization() {
      return this.organizationsStore.selectedOrganization;
    },
  },
  created() {
  },
  methods: {
    shouldShowTab(tabName) {
      let requiredPermissions = [`org.app.allow${tabName}`];

      const defaultToAllOrgsViewsWithoutExplicitPermissions = this.userStore.isPermitted(['platform.app.useAllOrganizationsModeViewsInOrganizationsWithoutExplicitPermissions']);
      const isInOrgWithoutExplicitPermissions = this.userStore.permissions.hasDistinctOrganizationPermissions === false;

      if (!this.selectedOrganization) {
        // 'all' mode selected
        requiredPermissions = [`platform.app.allow${tabName}InAllOrganizationsMode`];
      }

      if (this.selectedOrganization && defaultToAllOrgsViewsWithoutExplicitPermissions && isInOrgWithoutExplicitPermissions) {
        // user has an organization selected for which they don't have explicit permissions (e. g. b/c they have platform permissions)
        return this.userStore.isPermitted([`platform.app.allow${tabName}InAllOrganizationsMode`]);
      }

      return this.userStore.isPermitted(requiredPermissions);
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  left: 0em;
  top: 0em;
  width: 250px;
  height: 100vh;
  z-index: 1000;
  background: var(--color-background);
  border-right: 1px solid var(--color-border);
}

.sidebar-logo {
  height: var(--header-height);
  padding-left: 1.2em;
  padding-right: 1.2em;
  display: flex;
  align-items: center;
}

.sidebar-content {
  padding: 1.2em;
}

.tab:deep(.app-sidebar-item) {
  margin-bottom: 2px;
}

.tab:last-child:deep(.app-sidebar-item) {
  margin-bottom: 0px;
}

.organization-switch {
  margin-bottom: 1em;
}
</style>
