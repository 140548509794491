import axios from 'axios';

// eslint-disable-next-line import/extensions
import auth from '@/env/auth';

// eslint-disable-next-line import/extensions
import gatewayRequest from '@/utility/gateway-request';

let apiURL = 'https://platform-core.services.api.dealdate.net';

if (process.env.NODE_ENV === 'development') {
  apiURL = 'http://localhost:3000';
}

export default {
  async getUser() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // get id token
        const idToken = await auth.getIdToken();

        // send request
        const response = await axios.get(`${apiURL}/user`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        resolve(response.data);
      } catch (error) {
        reject(new Error('error'));
      }
    });
  },
  async getOrganizations() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = (await gatewayRequest('/user/organizations', {
          method: 'GET',
        })).data;

        // transform response
        const data = response?.data;

        // resolve
        resolve({
          data,
          metadata: null,
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async getOrganizationPermissions(config) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = (await gatewayRequest('/user/organizations/:organizationId/permissions', {
          method: 'GET',
          params: {
            organizationId: config?.organizationId,
          },
        })).data;

        // transform response
        const data = response?.data;

        // resolve
        resolve({
          data,
          metadata: null,
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async getPlatformPermissions() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = (await gatewayRequest('/user/platform/permissions', {
          method: 'GET',
        })).data;

        // transform response
        const data = response?.data;

        // resolve
        resolve({
          data,
          metadata: null,
        });
      } catch (error) {
        reject(error);
      }
    });
  },
};
