<template>
  <ion-page :class="{ 'canvas-mode': viewMode === 'canvas' }">
    <app-sidebar v-if="viewMode !== 'canvas'" class="hide-on-mobile" data-testid="sidebar" />
    <div>
      <div class="view-outlet">
        <router-view id="nav-router-outlet" class="dd-view" />
        <app-tab-nav v-if="viewMode !== 'canvas'" class="hide-on-desktop" />
      </div>
    </div>
  </ion-page>
</template>

<script>
import {
  // IonTabs,
  IonPage,
  // IonRouterOutlet,
} from '@ionic/vue';

import AppSidebar from '@/components/layout/AppSidebar.vue';
import AppTabNav from '@/components/layout/AppTabNav.vue';

export default {
  name: 'NavWrapper',
  components: {
    // IonTabs,
    IonPage,
    // IonRouterOutlet,
    AppSidebar,
    AppTabNav,
  },
  data() {
    return {
      viewMode: 'default',
    };
  },
  watch: {
    $route() {
      this.setViewMode();
    },
  },
  created() {
    this.setViewMode();
  },
  methods: {
    setViewMode() {
      if (this.$route.matched.length === 2) {
        const route = this.$route.matched[1];

        // canvas mode setting
        if (route.props.default) {
          if (route.props.default.canvasMode !== undefined) {
            this.viewMode = 'canvas';
          } else {
            this.viewMode = 'default';
          }
        } else {
          // fallback
          this.viewMode = 'default';
        }
      }
    },
  },
};
</script>

<style scoped>
.dd-view {
  max-width: calc(100vw - 250px);
  margin-left: 250px;
}

@media (max-width: 1050px) {
  .dd-view {
    margin-left: 0;
    max-width: 100vw;
  }
}

.canvas-mode .dd-view {
  margin-left: 0 !important;
  max-width: 100vw !important;
}
</style>
