import { defineStore } from 'pinia';

// eslint-disable-next-line import/extensions
import userApi from '@/api/user';

// eslint-disable-next-line import/extensions
import storage from '@/env/storage';

const useOrganizationsStore = defineStore('OrganizationsStore', {
  state() {
    return {
      organizations: [],
      selectedOrganization: {
      },
      permissions: {},
      error: '',
    };
  },
  actions: {
    async getOrganizations(config) {
      try {
        const organizations = await userApi.getOrganizations(config);

        this.organizations = organizations.data;

        // automatically set selectedOrganization
        const selectedOrganization = await storage.get('selected-organization');

        if (!selectedOrganization) {
          // storage has no selectedOrganization => use first organization
          this.selectOrganization(this.organizations[0]?.id);
        } else if (selectedOrganization === 'all') {
          // all organizations
          this.selectOrganization('all');
        } else {
          // storage already contains a selectedOrganization => set in state
          this.selectOrganization(selectedOrganization);
        }
      } catch (error) {
        console.log('Failed to fetch organizations', error);
        this.error = error.type;
      }
    },
    async selectOrganization(organizationId) {
      if (organizationId === 'all') {
        this.selectedOrganization = null;

        // set in storage
        storage.set('selected-organization', 'all');
        return;
      }

      const selectedOrganization = this.organizations.filter((organization) => {
        if (organization?.id !== organizationId) {
          return false;
        }

        return true;
      });

      if (selectedOrganization[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedOrganization = selectedOrganization[0];

        // set in storage
        storage.set('selected-organization', organizationId);
      }
    },
  },
  getters: {
  },
});

export default useOrganizationsStore;
