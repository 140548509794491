<template>
  <ui-router-link :to="to" direction="root">
    <div class="app-sidebar-item" :class="{ 'active': active }">
      <span class="label" data-testid="label">{{ label }}</span>
    </div>
  </ui-router-link>
</template>

<script>
import UiRouterLink from '@/components/ui/UIRouterLink.vue';

export default {
  name: 'AppSidebarItem',
  components: {
    UiRouterLink,
  },
  props: {
    to: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.app-sidebar-item {
  padding: 0.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-radius: 0.62em;
  transition: 0.1s;
}

.app-sidebar-item:hover {
  background: var(--color-background-secondary-inactive);
}

.app-sidebar-item.active {
  background: var(--color-background-secondary);
}

.app-sidebar-item.active .label {
  color: var(--color-primary);
}

.label {
  font-size: var(--font-size-small);
  color: var(--color-text);
}
</style>
