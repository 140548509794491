export default {
  de: {
    errors: {
    },
    nav: {
      sidebar: {
        ordersTab: 'Aufträge',
        returnsTab: 'Retouren',
        itemsTab: 'Artikel',
        moreTab: 'Mehr',
      },
      tabNav: {
        ordersTab: 'Aufträge',
        returnsTab: 'Retouren',
        itemsTab: 'Artikel',
        moreTab: 'Mehr',
      },
    },
    common: {
      traceId: 'Trace-ID: { traceId }',
      multiOrganizationViewNotSupported: 'Diese Ansicht ist nicht für die organisationsübergreifende Darstellung optimiert. Bitte wähle eine Organisation aus, um fortzufahren.',
      edit: 'Bearbeiten',
      cancel: 'Abbrechen',
      save: 'Speichern',
      back: 'Zurück',
      done: 'Fertig',
      ok: 'OK',
      print: 'Drucken',
      download: 'Download',
      admin: 'Admin',
      nobody: 'Niemand',
      all: 'Alle',
      orders: 'Aufträge',
      orderIdHumanReadable: '#{ orderId }',
      itemsCount: 'Keine Artikel | { count } Artikel | { count } Artikel',
      orderCommentsCount: 'Keine Notizen | { count } Notiz | { count } Notizen',
      advancedOptions: 'Erweiterte Optionen',
      assignedToYouOption: 'Dir ({ displayName })',
      featureComingSoon: 'Dieses Feature ist in Kürze verfügbar.',
      inStock: '{ inStock } verfügbar',
      quantityShort: '{ quantity }x',
      pageNumber: 'Seite { page }',
      itemsNOfTotal: '{ items } von { total }',
      variantIdNumber: '{ variantId }/Nr. { variantNumber }',
      modelName: 'Modell: { modelName }',
      externalId: 'Externe ID: { externalId }',
      attributes: 'Attribute: { attributes }',
      fromDate: 'Von',
      toDate: 'Bis',
      acceptCancelation: 'Stornierung akzeptieren',
      rejectCancelation: 'Stornierung ablehnen',
      accept: 'Akzeptieren',
      reject: 'Ablehnen',
      orderTypes: {
        orders: 'Aufträge | Auftrag | Aufträge',
        returns: 'Retouren | Retoure | Retouren',
        deliveryOrders: 'Lieferaufträge | Lieferauftrag | Lieferaufträge',
        creditNotes: 'Gutschriften | Gutschrift | Gutschriften',
        warranties: 'Gewährleistungen | Gewährleistung | Gewährleistungen',
      },
      fileInput: {
        nFilesSelected: 'Keine Datei ausgewählt | 1 Datei ausgewählt | { count } Dateien ausgewählt',
      },
      documentTypes: {
        returnNote: 'Rücksendeschein',
        deliveryNote: 'Lieferschein',
        deliveryNoteInfo: 'Verpflichtender Paketbeileger: Alle Marktplätze',
        proFormaInvoice: 'Proformarechnung',
        shippingLabel: 'Versandetikett',
        returnLabel: 'Retourenetikett',
        returnLabelInfo: 'Verpflichtender Paketbeileger: limango',
        creditNote: 'Gutschrift',
        invoice: 'Rechnung',
      },
      feeTypes: {
        deliveryFees: 'Versandkosten',
      },
      orderStates: {
        incompleteData: 'Unvollständige Daten',
        return: 'Retoure',
        open: 'Offen',
        returnReleased: 'Retoure freigegeben',
        authorizeReturnWithOwnLabel: 'Retoure mit eigenem Etikett autorisieren ({ marketplaceName })',
        currentlyShipping: 'Im Versand',
        shipmentComplete: 'Zugestellt',
        incompleteOrder: 'Auftrag unvollständig',
        initiateRefund: 'Rückerstattung veranlassen ({ marketplaceName })',
        transferRefund: 'Rückerstattung übertragen ({ marketplaceName })',
      },
      permissionEntities: {
        orders: 'Aufträge',
        items: 'Artikel',
        documents: 'Dokumente',
        users: 'Nutzer:innen',
      },
      permissions: {
        read: 'Ansehen',
        write: 'Hinzufügen',
        update: 'Bearbeiten',
        delete: 'Löschen',
        updateStatus: 'Status ändern',
      },
      passwordRequirements: {
        title: 'Anforderungen',
        description: 'Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Groß- sowie Kleinbuchstaben und eine Zahl enthalten.',
      },
      unitPriceLabel: 'Einzel',
      totalPriceLabel: 'Gesamt',
      logout: 'Abmelden',
      logIn: 'Anmelden',
      marketplaces: {
        amazon: 'Amazon',
        amazonGermanyB2B: 'Amazon Germany B2B',
        eBay: 'eBay',
        kaufland: 'Kaufland',
        otto: 'OTTO',
        myToys: 'myToys',
        dealdate: 'DealDate Marketplace',
        gipfelstuermer: 'Gipfelstürmer',
        limango: 'limango',
        metro: 'METRO',
        manual: 'Manuell',
        shopify: 'Shopify',
      },
      statuses: {
        waitingForData: 'Warten auf Daten',
        waitingForPayment: 'Zahlung ausstehend',
        shipmentPreparation: 'Vorbereiten',
        incompleteOrder: 'Auftrag unvollständig',
        open: 'Offen',
        dispatched: 'Ausgeführt',
        canceled: 'Storno',
        canceledByCustomer: 'Kund:innenstorno',
        canceledByPartner: 'Partner:innenstorno',
        return: 'Retoure',
        returnAccepted: 'Retoure akzeptiert',
        returnRejected: 'Retoure abgelehnt',
        creditNote: 'Gutschrift',
        creditNotePaid: 'Rückerstattet',
        creditNoteFailed: 'Gutschrift fehlgeschlagen',
        warranty: 'Gewährleistung',
        warrantyAccepted: 'Gewährleistung akzeptiert',
        warrantyRejected: 'Gewährleistung abgelehnt',
        partialRefund: 'Teilerstattung',
        partialRefundPaid: 'Teilerstattung erfolgreich',
        repair: 'Reparatur',
        collectiveOrder: 'Sammelauftrag',
        collectiveCreditNote: 'Sammelgutschrift',
        archived: 'Archiviert',
        testOrder: 'Test-Auftrag',
        invalid: 'Ungültig',
        cancelationRequest: 'Stornoanfrage',
        cancelationImpossible: 'Stornierung abgelehnt',
      },
      selectAll: 'Alle auswählen',
      selectAllCount: 'Alle auswählen ({ count })',
      clearSelection: 'Auswahl aufheben',
      ordersSelected: {
        orders: 'Kein Auftrag ausgewählt | 1 Auftrag ausgewählt | { count } Aufträge ausgewählt',
        deliveryOrders: 'Kein Lieferauftrag ausgewählt | 1 Lieferauftrag ausgewählt | { count } Lieferaufträge ausgewählt',
        returns: 'Keine Retoure ausgewählt | 1 Retoure ausgewählt | { count } Retouren ausgewählt',
        creditNotes: 'Keine Gutschrift ausgewählt | 1 Gutschrift ausgewählt | { count } Gutschriften ausgewählt',
        warranties: 'Keine Gewährleistung ausgewählt | 1 Gewährleistung ausgewählt | { count } Gewährleistungen ausgewählt',
      },
      executeOrder: 'Auftrag ausführen',
      acceptReturn: 'Retoure akzeptieren',
      executeOrderCount: 'Auftrag ausführen ({ count })',
      acceptReturnCount: 'Retoure akzeptieren ({ count })',
      sync: 'Synchronisieren',
    },
    views: {
      orders: {
        title: 'Aufträge',
        titleCount: 'Aufträge ({ count })',
      },
      returns: {
        titleCount: 'Retouren ({ count })',
        filters: {
          open: 'Offene Retouren',
          closed: 'Geschlossene Retouren',
          sentToDealDate: 'An DealDate gesendet',
        },
      },
      settingsUserDetail: {
        access: {
          headline: 'Zugang',
          email: 'E-Mail-Adresse',
          password: 'Passwort',
          sendPasswordResetEmail: 'Passwort zurücksetzen',
        },
        permissions: {
          headline: 'Berechtigungen',
        },
        profile: {
          headline: 'Profil',
          firstName: 'Vorname',
          lastName: 'Nachname',
        },
      },
      orderDetail: {
        title: '{ customerName }, #{ orderId }',
        overview: {
          headline: 'Überblick',
          lastModification: 'Letzte Änderung',
          orderTotal: 'Bestellsumme',
          deliveryFees: 'Versandkosten',
          marketplace: 'Marktplatz',
          paymentReceived: 'Zahlungseingang',
        },
        fees: {
          headline: 'Gebühren ({ fees })',
        },
        discounts: {
          headline: 'Rabatte ({ discounts })',
        },
        documents: {
          headline: 'Auftrags- und Versanddokumente',
          returnLabel: 'Retourenetikett',
          proFormaInvoice: 'Proformarechnung',
          shippingLabel: 'Versandetikett',
          returnReceipt: 'Rücksendeschein',
          deliveryBill: 'Lieferschein',
          marketplaceSpecificReturnInstructions: 'Retourenanweisungen { marketplaceName }',
        },
        responsibilities: {
          headline: 'Verantwortlichkeiten',
          assignedPersonHeadline: 'Zugewiesene Person',
          orderExecutedByHeadline: 'Auftrag ausgeführt von',
          returnAcceptedByHeadline: 'Retoure akzeptiert von',
          nobody: 'Niemand',
        },
        orderDetails: {
          headline: 'Auftragsdetails',
        },
        billingAddress: {
          headline: 'Rechnungsadresse',
        },
        deliveryAddress: {
          headline: 'Lieferadresse',
        },
        trackingNumber: {
          headline: 'Sendungsnummer',
          noTrackingNumber: 'Keine Trackingdaten vorhanden',
        },
      },
      items: {
        title: 'Artikel',
      },
      more: {
        title: 'Mehr',
        manageUsersButton: 'Nutzer:innen',
        managePrintersButton: 'Drucker',
        documentsButton: 'Dokumente',
        contactButton: 'Kontakt',
        newsButton: 'Neuigkeiten',
        legalNoticeButton: 'Impressum',
        privacyPolicyButton: 'Datenschutz',
        signOutButton: 'Abmelden',
        platformHeadline: 'Plattform',
        plentymarketsButton: 'plentymarkets',
        amazonButton: 'Amazon',
        platformExportButton: 'Exportieren',
      },
      documents: {
        title: 'Dokumente',
      },
      settingsUsers: {
        title: 'Nutzer:innen',
        addUserButton: 'Nutzer:in hinzufügen',
      },
      settingsPrinters: {
        title: 'Drucker',
        addPrinterButton: 'Drucker hinzufügen',
      },
      legalNotice: {
        title: 'Impressum',
      },
      privacyPolicy: {
        title: 'Datenschutz',
      },
      plentymarkets: {
        title: 'plentymarkets',
        syncOrders: {
          syncByOrderId: {
            headline: 'Auftrag synchronisieren',
            // description: 'Ein Auftrag dessen ID Du kennst wird nicht im DealDate-Portal gezeigt oder ist nicht aktuell?<br>Dann trag unten einfach die Auftrags-ID aus plentymarkets ein, damit DealDate die aktuellsten Daten abrufen kann.',
            syncButton: 'Synchronisieren',
            orderIdPlaceholder: 'Auftrags-ID',
          },
          syncByDateRange: {
            headline: 'Aufträge synchronisieren (Batch)',
            // description: 'Durch einen Ausfall sind bestimmte Auftragsdaten inkonsistent?<br>Wir rufen dynamisch alle Aufträge im gewählten Zeitraum von plentymarkets ab.',
            hint: 'Je nach Auftragsmenge kann die Synchronisierung etwas Zeit in Anspruch nehmen.',
            syncButton: 'Aufträge synchronisieren',
          },
          orderSyncTasks: {
            headline: 'In Arbeit',
            taskTitle: '1 Auftrag | 1 Auftrag | { count } Aufträge',
            progressLabel: '{ syncedOrders } von { totalOrders }',
          },
        },
        dialogs: {
          orderSynced: {
            title: 'Auftrag synchronisiert',
            description: 'Der Auftrag { orderId } wurde erfolgreich von plentymarkets abgerufen und mit DealDate synchronisiert.',
          },
          ordersSynced: {
            title: 'Aufträge werden synchronisiert',
            description: 'Die Aufträge zwischen { fromDate } und { toDate } werden jetzt synchronisiert. Das kann je nach Anzahl der Aufträge eine Weile dauern.',
          },
          syncingOrdersFailed: {
            title: 'Synchronisierung fehlgeschlagen',
            description: 'Die Aufträge zwischen { fromDate } und { toDate } konnten nicht synchronisiert werden. Versuche, einen kürzeren Zeitraum zu wählen, wenn das Problem erneut auftreten sollte.',
          },
        },
      },
      amazon: {
        title: 'Amazon',
        returnsImport: {
          headline: 'Retouren importieren',
          exportButtonLabel: 'Import starten',
          fileUpload: {
            label: 'Returns Report-Datei auswählen',
            supportedFormatsLabel: 'Unterstützte Formate: XML',
          },
          dialogs: {
            genericError: {
              title: 'Ein Problem ist aufgetreten',
              description: 'Leider gab es ein Problem bei der Verarbeitung der Datei. Bitte versuche es erneut.',
            },
            validationError: {
              title: 'Ungültige Datei',
              description: 'Die hochgeladene Datei scheint nicht das korrekte Format zu haben. Unterstützt werden "Returns Reports" von Amazon im XML-Format und mit der Schema-Version 1.',
            },
            alreadyImportedError: {
              title: 'Die Datei wurde bereits hochgeladen.',
              description: 'Die hochgeladene Datei wurde bereits importiert und kann nicht erneut hochgeladen werden.',
            },
            success: {
              title: 'Import erfolgreich',
              description: 'Die Retouren aus der XML-Datei wurden erfolgreich in plentymarkets angelegt und mit der DealDate-Plattform synchronisiert.',
            },
            partialSuccess: {
              title: 'Import teilweise erfolgreich',
              description: 'Einige Retouren aus der hochgeladenen Datei wurden erfolgreich importiert.<br /><br />Ein Teil der Retouren konnte jedoch nicht verarbeitet werden. Ein Grund könnte ein Problem mit der plentymarkets API sein.<br /><br />Weitere Infos zu den fehlgeschlagenen Retouren findest Du in der folgenden Fehlermeldung: <br /><br />{ errorMessage }',
            },
          },
        },
      },
      platformExport: {
        title: 'Exportieren',
        deliveryStatus: {
          headline: 'Lieferstatus als CSV exportieren',
          exportButtonLabel: 'Lieferstatus exportieren',
        },
      },
      auth: {
        title: 'Willkommen bei DealDate.',
        description: 'Melde Dich mit Deinem DealDate-Account an, um Zugriff auf alle Funktionen zu erhalten.',
        fields: {
          email: {
            label: 'E-Mail',
          },
          password: {
            label: 'Passwort',
          },
        },
        resetPasswordButton: 'Passwort vergessen',
      },
    },
    modals: {
    },
    dialogs: {
      confirmOrderExecution: {
        title: 'Auftrag ausführen | Auftrag ausführen | { count } Aufträge ausführen',
        description: 'Möchtest Du wirklich 1 Auftrag ausführen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Auftrag ausführen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Aufträge ausführen? Diese Aktion kann nicht widerrufen werden.',
        executeOrdersButton: 'Auftrag ausführen | Auftrag ausführen | Aufträge ausführen',
      },
      confirmReturnAcceptance: {
        title: 'Retoure akzeptieren | Retoure akzeptieren | { count } Retouren akzeptieren',
        description: 'Möchtest Du wirklich 1 Retoure akzeptieren? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Retoure akzeptieren? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Retouren akzeptieren? Diese Aktion kann nicht widerrufen werden.',
        acceptReturnsButton: 'Retoure akzeptieren | Retoure akzeptieren | Retouren akzeptieren',
      },
      confirmReturnRejection: {
        title: 'Retoure ablehnen | Retoure ablehnen | { count } Retouren ablehnen',
        description: 'Möchtest Du wirklich 1 Retoure ablehnen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Retoure ablehnen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Retouren ablehnen? Diese Aktion kann nicht widerrufen werden.',
        confirmButton: 'Retoure ablehnen | Retoure ablehnen | Retouren ablehnen',
        orderCommentCheckbox: 'Notiz zum Auftrag hinzufügen',
      },
      confirmCancelationAcceptance: {
        title: 'Stornierung akzeptieren | Stornierung akzeptieren | { count } Stornierungen akzeptieren',
        description: 'Möchtest Du wirklich 1 Stornierung akzeptieren? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Stornierung akzeptieren? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Stornierungen akzeptieren? Diese Aktion kann nicht widerrufen werden.',
        confirmButton: '1 akzeptieren | 1 akzeptieren | { count } akzeptieren',
      },
      rejectCancelationAcceptance: {
        title: 'Stornierung ablehnen | Stornierung ablehnen | { count } Stornierungen ablehnen',
        description: 'Möchtest Du wirklich 1 Stornierung ablehnen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Stornierung ablehnen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Stornierungen ablehnen? Diese Aktion kann nicht widerrufen werden.',
        confirmButton: '1 ablehnen | 1 ablehnen | { count } ablehnen',
      },
      requestCancelation: {
        title: 'Stornierung anfragen | Stornierung anfragen | { count } Stornierungen anfragen',
        description: 'Möchtest Du wirklich 1 Stornierung anfragen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Stornierung anfragen? Diese Aktion kann nicht widerrufen werden. | Möchtest Du wirklich { count } Stornierungen anfragen? Diese Aktion kann nicht widerrufen werden.',
        confirmButton: 'Stornierung anfragen | Stornierung anfragen | { count } Stornierungen anfragen',
      },
      resetPassword: {
        title: 'Passwort zurücksetzen',
        description: 'Bitte gib Deine Login-E-Mail-Adresse ein, an die wir einen Link zum Zurücksetzen des Passworts senden sollen.',
        resetLabel: 'E-Mail senden',
      },
      sendToDealDate: {
        title: 'Retoure an DealDate senden',
        description: 'Du benötigst Unterstützung?<br /><br />Gib bitte über das untenstehende Feld Details zum Problem mit dieser Retoure an, damit sich unser Team um die Retoure kümmern kann.',
        confirmButton: 'An DealDate senden',
      },
      printDocuments: {
        title: 'Dokumente drucken',
        noDocumentsAvailable: 'Keine Dokumente vorhanden.',
        documentTypes: {
          returnNote: 'Rücksendeschein (1) | Rücksendeschein ({ count }) | Rücksendescheine ({ count })',
          deliveryNote: 'Lieferschein (1) | Lieferschein ({ count }) | Lieferscheine ({ count })',
          proFormaInvoice: 'Proformarechnung (1) | Proformarechnung ({ count }) | Proformarechnungen ({ count })',
          shippingLabel: 'Versandetikett (1) | Versandetikett ({ count }) | Versandetiketten ({ count })',
          returnLabel: 'Retourenetikett (1) | Retourenetikett ({ count }) | Retourenetiketten ({ count })',
          invoice: 'Rechnung (1) | Rechnung ({ count }) | Rechnungen ({ count })',
          creditNote: 'Gutschrift (1) | Gutschrift ({ count }) | Gutschriften ({ count })',
        },
        preparingFilesForDownload: 'Dateien werden für den Download vorbereitet. Dies kann einige Minuten dauern.',
        preparingFilesForPrinting: 'Dateien werden für den Druck vorbereitet. Dies kann einige Minuten dauern.',
        download: 'Download',
        print: 'Drucken',
        selectPrinter: {
          label: 'Drucker wählen',
        },
        printSucceeded: {
          title: 'Dokumente werden gedruckt.',
          description: 'Die Dokumente werden jetzt einzeln an den Drucker "{ printerName }" zum Druck übergeben.<br>Dies kann ein paar Minuten dauern.',
        },
        smartPrinting: {
          headline: 'Smartes Drucken',
          description: 'Drucke die erforderlichen Dokumente einfach gesammelt nach Auftrag aus.<br><br>Von Marktplätzen nicht benötigte Dokumente werden nicht mit gedruckt.',
          order: {
            headlineDINA4: 'Druckreihenfolge DIN A4',
            headlineDINA5: 'Druckreihenfolge DIN A5',
          },
          downloadDINA4Button: 'Download A4',
          downloadDINA5Button: 'Download A5',
        },
      },
      createManagedUser: {
        title: 'Nutzer:in hinzufügen',
        createUserButton: 'Nutzer:in erstellen',
      },
      createReturn: {
        title: 'Retoure anlegen',
        description: 'Auftrag: #{ orderId }',
        selectOtherOrder: 'Anderen Auftrag wählen',
        createReturnButton: 'Retoure anlegen',
        successMessage: 'Retoure erfolgreich angelegt',
        returnReason: {
          headline: 'Retourengrund wählen',
        },
        quantity: {
          headline: 'Anzahl wählen',
          allLabel: 'Alle ({ quantity })',
        },
        selectOrder: {
          headline: 'Finde einen Auftrag',
        },
        selectItems: {
          headline: 'Welche Artikel sollen zurückgegeben werden?',
        },
        summary: {
          headline: 'Zusammenfassung',
          itemsSelected: '0 Artikel für Retoure gewählt | { count } Artikel für Retoure gewählt | { count } Artikel für Retoure gewählt',
        },
      },
    },
    popovers: {
      orderActions: {
        assignToMe: 'Mir zuweisen',
        unassignFromMe: 'Zuweisung aufheben',
        assignToMeCount: 'Mir zuweisen ({ count })',
        unassignFromMeCount: 'Zuweisung aufheben ({ count })',
        executeOrder: 'Auftrag ausführen',
        acceptReturn: 'Retoure akzeptieren',
        rejectReturn: 'Retoure ablehnen',
        executeOrderCount: 'Auftrag ausführen | Auftrag ausführen | Aufträge ausführen ({ count })',
        acceptReturnCount: 'Retoure akzeptieren | Retoure akzeptieren | Retouren akzeptieren ({ count })',
        acceptCancelation: 'Anfrage akzeptieren',
        rejectCancelation: 'Anfrage ablehnen',
        acceptCancelationCount: 'Anfrage akzeptieren | Anfrage akzeptieren | Anfragen akzeptieren ({ count })',
        rejectCancelationCount: 'Anfrage ablehnen | Anfrage ablehnen | Anfragen ablehnen ({ count })',
        printDocuments: 'Dokumente drucken …',
        createReturn: 'Retoure anlegen',
        requestCancelation: 'Stornierung anfragen',
      },
    },
    ui: {
      executeButton: {
        label: 'Auftrag ausführen',
        successLabel: 'Erfolgreich ausgeführt',
        revokeLabel: 'Widerrufen? ({ counter })',
      },
      documentCard: {
        adminOnly: 'Nur für Admins sichtbar',
      },
      orderCard: {
        pendingPayment: 'Zahlung ausstehend',
        paid: 'Bezahlt am { paymentDate }',
      },
      itemCard: {
        variants: 'Keine Varianten | 1 Variante | { count } Varianten',
      },
      pagination: {
        backToFirstPageButton: 'Seite 1 aufrufen',
      },
    },
    units: {
      orders: {
        assignedToMe: 'Mir zugewiesen',
        assignedTo: 'Zugewiesen:',
        filters: {
          all: 'Alle',
          notReady: 'Nicht bereit',
          orders: 'Aufträge',
          priorityOrders: 'Prime-Aufträge',
          openOrders: 'Offene Aufträge',
          closedOrders: 'Geschlossene Aufträge',
          returns: 'Retouren',
          openReturns: 'Offene Retouren',
          closedReturns: 'Geschlossene Retouren',
          creditNotes: 'Gutschriften',
          openCredits: 'Offene Gutschriften',
          closedCredits: 'Geschlossene Gutschriften',
          cancelations: 'Stornierungen',
          warranties: 'Gewährleistungen',
          openWarranties: 'Offene Gewährleistungen',
          closedWarranties: 'Geschlossene Gewährleistungen',
          cancelationRequests: 'Stornierungsanfragen',
          open: 'Offene',
          closed: 'Geschlossene',
        },
        sortOptions: {
          created: 'Datum',
          createdAsc: 'Datum (aufsteigend)',
          createdDesc: 'Datum (absteigend)',
          modified: 'Letzte Änderung',
          modifiedAsc: 'Letzte Änderung (aufsteigend)',
          modifiedDesc: 'Letzte Änderung (absteigend)',
          orderId: 'ID',
          orderIdAsc: 'ID (aufsteigend)',
          orderIdDesc: 'ID (absteigend)',
        },
        limitOptions: {
          nResults: '{ count } Ergebnisse',
        },
        search: {
          fieldPlaceholder: 'ID oder Suchbegriffe …',
        },
        marketplaceFilter: {
          label: 'Marktplatz',
        },
        shippingFilter: {
          label: 'Versandpriorität',
          standard: 'Standard',
          priority: 'Prime',
        },
        assignmentFilter: {
          contextBar: {
            title: 'Zugewiesene Aufträge ({ count })',
            printAssignedOrdersButton: {
              printAll: 'Alle Dokumente drucken',
              printNDocuments: '0 Dokumente drucken | 1 Dokument drucken | { count } Dokumente drucken',
            },
          },
        },
        noOrdersFound: 'Keine Aufträge gefunden.',
      },
      items: {
        filters: {
          all: 'Alle',
          lowStock: 'Geringer Lagerbestand',
          outOfStock: 'Nicht verfügbar',
        },
      },
      users: {
        errors: {
          common: 'Leider ist etwas schief gelaufen. Bitte aktualisiere Dein Browserfenster, um es erneut zu versuchen.',
          unauthorized: 'Nur Admins können Nutzer:innen verwalten.',
        },
      },
      printers: {
        addButton: 'Drucker hinzufügen',
        deleteButton: 'Drucker löschen',
        addPrinter: {
          headline: 'Drucker hinzufügen',
        },
        printers: {
          headline: 'Deine Drucker',
        },
        errors: {
          common: 'Leider ist etwas schief gelaufen. Bitte aktualisiere Dein Browserfenster, um es erneut zu versuchen.',
          unauthorized: 'Dir fehlen die benötigten Berechtigungen, um Drucker zu verwalten.',
        },
      },
      documents: {
        errors: {
          common: 'Leider ist etwas schief gelaufen. Bitte aktualisiere Dein Browserfenster, um es erneut zu versuchen.',
          unauthorized: 'Dir fehlen die benötigten Berechtigungen, um Dokumente anzusehen.',
        },
      },
      shipmentTracking: {
        headline: 'Aktueller Status',
        errors: {
          notFound: 'Keine Daten zur Sendungsverfolgung verfügbar.',
        },
        sourceFootnote: {
          dhl: 'Delivered by Deutsche Post DHL Group',
        },
        showStatusHistory: 'Statushistorie anzeigen',
        hideStatusHistory: 'Statushistorie verstecken',
      },
      orderComments: {
        contextMenu: {
          editComment: 'Bearbeiten',
        },
        dialogs: {
          createOrderComment: {
            title: 'Auftragsnotiz erstellen',
          },
          updateOrderComment: {
            title: 'Auftragsnotiz bearbeiten',
          },
        },
        orderCommentForm: {
          commentTextLabel: 'Auftragsnotiz',
          commentTextPlaceholder: 'z. B.: "Zurückgegebener Artikel hat Gebrauchsspuren"',
        },
      },
      returnsList: {
        contextMenu: {
          sendToDealDate: 'An DealDate senden',
          createOrderComment: 'Neue Auftragsnotiz …',
          openParentOrder: 'Hauptauftrag aufrufen',
        },
        warnings: {
          tooltips: {
            creditNoteAvailable: 'Keine Gutschrift vorhanden | Gutschrift vorhanden ({ orderIds }) | Gutschriften vorhanden ({ orderIds })',
            alreadyRefunded: 'Achtung: Es gibt bereits erstattete Gutschriften für zugehörige Aufträge.<br>Prüfe bitte alle Angaben besonders genau, bevor Du eine Entscheidung triffst.',
          },
        },
        errors: {
          common: 'Leider gab es ein Problem beim Laden der Retouren. Bitte aktualisiere Dein Browserfenster, um es erneut zu versuchen.',
          noOrders: 'Keine Retouren gefunden.',
          missingViewPermissions: 'Du hast nicht die benötigte Berechtigungsstufe, um diese Ansicht zu verwenden.',
        },
        deliveryAddress: {
          headline: 'Lieferadresse',
        },
      },
    },
    orders: {
      orderedItems: {
        headline: 'Bestellte Artikel ({ orderedItems })',
      },
      orderComments: {
        headline: 'Notizen ({ count })',
      },
      orderReferences: {
        headline: 'Zugehörige Aufträge ({ count })',
      },
      orderReferenceCard: {
        label: '{ orderType } #{orderId}',
      },
    },
    forms: {
      login: {
        errors: {
          common: {
            incorrectCredentials: 'Die eingegebene Kombination aus E-Mail-Adresse und Passwort ist leider falsch.',
          },
        },
      },
      editShipmentTracking: {
        fields: {
          trackingNumber: {
            label: 'Trackingnummer',
          },
        },
        dhlNote: 'Hinweis: Aktuell akzeptieren wir nur Sendungsnummern von DHL.',
      },
      managePrinter: {
        name: 'Name des Druckers',
        email: 'E-Mail',
      },
      createManagedUser: {
        fields: {
          firstName: {
            label: 'Vorname',
          },
          lastName: {
            label: 'Nachname',
          },
          email: {
            label: 'E-Mail-Adresse',
          },
          password: {
            label: 'Passwort',
          },
        },
      },
      plentymarkets: {
        syncOrderById: {
          forceXMLRegeneration: {
            label: 'XML-Dateien erneut generieren',
            subtitle: 'Warnung: XML-Dateien werden erneut generiert, auch wenn diese bereits vorhanden sind.',
            confirmationDialog: {
              title: 'Synchronisierung bestätigen',
              description: 'Die ausgewählte Option "XML Dateien erneut generieren" kann zu Duplikaten führen. Möchtest Du wirklich fortfahren?',
              confirmLabel: 'Synchronisieren',
            },
          },
        },
      },
    },
    footer: {
      legalNotice: 'Impressum',
      privacyPolicy: 'Datenschutz',
    },
  },
};
