<template>
  <div class="tab-nav">
    <div class="tab-nav-inner">
      <app-tab-nav-item v-if="shouldShowTab('OrdersView')" :to="{ name: 'Orders' }" :label="$t('nav.tabNav.ordersTab')" :active="currentRoute === 'Orders'" data-testid="orders-tab" class="tab">
        <icon-inbox />
      </app-tab-nav-item>
      <app-tab-nav-item v-if="shouldShowTab('ReturnsView')" :to="{ name: 'Returns' }" :label="$t('nav.tabNav.returnsTab')" :active="currentRoute === 'Returns'" data-testid="returns-tab" class="tab">
        <icon-check-to-slot />
      </app-tab-nav-item>
      <app-tab-nav-item v-if="shouldShowTab('ItemsView')" :to="{ name: 'Items' }" :label="$t('nav.tabNav.itemsTab')" :active="currentRoute === 'Items'" data-testid="items-tab" class="tab">
        <icon-boxes />
      </app-tab-nav-item>
      <app-tab-nav-item v-if="shouldShowTab('MoreView')" :to="{ name: 'More' }" :label="$t('nav.tabNav.moreTab')" :active="currentRoute === 'More'" data-testid="more-tab" class="tab">
        <icon-more />
      </app-tab-nav-item>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import useUserStore from '@/stores/UserStore';
// eslint-disable-next-line import/extensions
import useOrganizationsStore from '@/stores/OrganizationsStore';

import IconInbox from '@/components/icons/IconInbox.vue';
import IconCheckToSlot from '@/components/icons/IconCheckToSlot.vue';
import IconBoxes from '@/components/icons/IconBoxes.vue';
import IconMore from '@/components/icons/IconMore.vue';

import AppTabNavItem from '@/components/layout/AppTabNavItem.vue';

export default {
  name: 'AppTabNav',
  components: {
    // IonTabBar,
    IconInbox,
    IconCheckToSlot,
    IconBoxes,
    IconMore,
    AppTabNavItem,
  },
  props: {
  },
  setup() {
    const userStore = useUserStore();
    const organizationsStore = useOrganizationsStore();

    return {
      userStore,
      organizationsStore,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    user() {
      return this.userStore.user;
    },
    selectedOrganization() {
      return this.organizationsStore.selectedOrganization;
    },
  },
  methods: {
    shouldShowTab(tabName) {
      let requiredPermissions = [`org.app.allow${tabName}`];

      const defaultToAllOrgsViewsWithoutExplicitPermissions = this.userStore.isPermitted(['platform.app.useAllOrganizationsModeViewsInOrganizationsWithoutExplicitPermissions']);
      const isInOrgWithoutExplicitPermissions = this.userStore.permissions.hasDistinctOrganizationPermissions === false;

      if (!this.selectedOrganization) {
        // 'all' mode selected
        requiredPermissions = [`platform.app.allow${tabName}InAllOrganizationsMode`];
      }

      if (this.selectedOrganization && defaultToAllOrgsViewsWithoutExplicitPermissions && isInOrgWithoutExplicitPermissions) {
        // user has an organization selected for which they don't have explicit permissions (e. g. b/c they have platform permissions)
        return this.userStore.isPermitted([`platform.app.allow${tabName}InAllOrganizationsMode`]);
      }

      return this.userStore.isPermitted(requiredPermissions);
    },
  },
};
</script>

<style scoped>
.tab-nav {
  position: fixed;
  left: 0em;
  bottom: 0em;
  width: 100vw;
  height: var(--tab-nav-height);
  z-index: 1000;
  background: var(--color-background);
  border-top: 1px solid var(--color-border);
  padding-bottom: var(--safe-area-bottom);
}

.tab-nav-inner {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: var(--default-content-padding);
  padding-right: var(--default-content-padding);
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.tab-nav-inner .tab {
  flex-basis: 33.33%;
}

ion-tab-bar {
  height: var(--tab-nav-height);
}
</style>
