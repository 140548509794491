import {
  popoverController,
} from '@ionic/vue';

export default {
  async show(popoverComponent, event, config, props, callback) {
    return new Promise((resolve) => {
      popoverController.create({
        component: popoverComponent,
        componentProps: {
          ...props,
          resolve(value) {
            popoverController.dismiss();

            if (callback) {
              try {
                callback(value);
              } catch (error) {
                console.log('Failed to call popover callback.');
              }
            }
          },
        },
        side: config.side || 'left',
        ...config,
        event, // event is needed to position the popover correctly
        translucent: true,
        arrow: false,
      })
        .then((popoverElement) => resolve(popoverElement.present()));
    });
  },
};
