export default {
  async set(key, value) {
    let preparedValue = value;

    if (typeof value === 'object') {
      preparedValue = JSON.stringify(value);
    }

    return localStorage.setItem(key, preparedValue);
  },
  async get(key) {
    let result = localStorage.getItem(key);

    try {
      result = JSON.parse(result);
    // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return result;
    }
  },
};
